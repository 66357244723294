<template>
  <div>
    <b-table-simple small responsive v-if="studyUnitsFilter.length > 0">
      <b-thead>
        <b-tr>
          <!-- <b-th class="text-align-center evaluation-header">
            {{
              $getVisibleNames(
                "teaching.ramicro",
                false,
                "RA Micro"
              ).toUpperCase()
            }}
          </b-th> -->
          <b-th class="text-align-center evaluation-header">
            {{
              $getVisibleNames(
                "manual.criterio_de_evaluacion",
                true,
                "Criterios de Evaluación"
              ).toUpperCase()
            }}
          </b-th>
          <b-th class="text-align-center evaluation-header">
            INSTRUMENTOS ESCRITOS
          </b-th>
          <b-th class="text-align-center evaluation-header">
            INSTRUMENTOS DE OBESERVACIÓN
          </b-th>
          <b-th class="text-align-center evaluation-header"> MENCIONES </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="study_unit in studyUnitsFilter">
          <!-- index -->
          <b-tr
            v-for="e_c_micro in ECMicroByStudyUnits(study_unit.id)"
            :key="`matter-${matter_id}-study_unit-${study_unit.id}-e_c_micro-${e_c_micro.id}`"
          >
            <!-- <b-td
              v-if="index == 0"
              :rowspan="ECMicroByStudyUnits(study_unit.id).length"
            >
              <div class="d-flex ml-1">
                <span> {{ study_unit.order }}.</span>
                <SentenceContainer
                  :unique_id="`s_u-matter-${matter_id}-study_unit-${study_unit.id}-e_c_micro-${e_c_micro.id}`"
                  :showColorCognitiveLevel="false"
                  :Sentence="study_unit"
                  :boundedTextContainer="short_view"
                  :single_line="short_view"
                ></SentenceContainer>
              </div>
            </b-td> -->
            <b-td
              :class="{
                'text-danger':
                  getQuestionIndicators(e_c_micro.id).length +
                    getRubricCriteriaIndicators(e_c_micro.id).length ==
                  0,
              }"
            >
              <div class="ml-1 mt-1">
                <SentenceContainer
                  :unique_id="`ecm-matter-${matter_id}-study_unit-${study_unit.id}-e_c_micro-${e_c_micro.id}`"
                  :showColorCognitiveLevel="show_taxonomy_color"
                  :Sentence="e_c_micro"
                  :boundedTextContainer="short_view"
                  :single_line="short_view"
                >
                  <template v-slot:order>
                    <span>
                      <span>
                        <span v-if="study_unit.competence_unit_order != 999">
                          {{ study_unit.competence_unit_order }}.</span
                        >{{ study_unit.order }}.{{ e_c_micro.order }}
                      </span>
                    </span>
                  </template>
                </SentenceContainer>
              </div>
            </b-td>
            <b-td class="text-center align-center">
              {{ getQuestionIndicators(e_c_micro.id).length }}
            </b-td>
            <b-td class="text-center align-center"
              >{{ getRubricCriteriaIndicators(e_c_micro.id).length }}
            </b-td>
            <b-td class="text-center align-center">
              <b-badge
                :id="`e_c_micro-popover-${e_c_micro.id}`"
                :variant="
                  getQuestionIndicators(e_c_micro.id).length +
                    getRubricCriteriaIndicators(e_c_micro.id).length ==
                  0
                    ? 'danger'
                    : filterMentionsTest(e_c_micro.id).length > 0
                    ? 'success'
                    : filterMentionsTest(e_c_micro.id).length > 0
                    ? 'warning'
                    : 'secondary'
                "
                v-b-tooltip.v-danger.top.noninteractive.hover="
                  getQuestionIndicators(e_c_micro.id).length +
                    getRubricCriteriaIndicators(e_c_micro.id).length ==
                  0
                    ? 'No está siendo mencionado en ninguna Evaluación'
                    : ''
                "
              >
                {{
                  getQuestionIndicators(e_c_micro.id).length +
                  getRubricCriteriaIndicators(e_c_micro.id).length
                }}
              </b-badge>
              <b-popover
                v-if="filterMentionsTest(e_c_micro.id).length > 0"
                :target="`e_c_micro-popover-${e_c_micro.id}`"
                placement="left"
                triggers="hover click"
                variant="dark"
              >
                <div>
                  <div v-if="filterMentionsTest(e_c_micro.id).length > 0">
                    <strong v-if="filterMentionsTest(e_c_micro.id).length > 1"
                      >{{
                        $getVisibleNames(
                          "evaluations2.test",
                          true,
                          "Instrumentos"
                        )
                      }}:</strong
                    >
                    <strong v-if="filterMentionsTest(e_c_micro.id).length == 1">
                      {{
                        $getVisibleNames(
                          "evaluations2.test",
                          false,
                          "Instrumento"
                        )
                      }}:
                    </strong>
                  </div>
                  <li
                    v-for="(filter_evaluations, index) in filterMentionsTest(
                      e_c_micro.id
                    )"
                    :key="index + 'filter_mentions_evaluations'"
                  >
                    {{ filter_evaluations }}
                  </li>
                </div>
              </b-popover>
            </b-td>
          </b-tr>
          <!-- <template v-if="ECMicroByStudyUnits(study_unit.id).length == 0">
            <b-tr :key="`matter-${matter_id}-study_unit-${study_unit.id}`">
              <b-td>
                <div class="d-flex ml-1 text-danger">
                  <SentenceContainer
                    :unique_id="`s_u-matter-${matter_id}-study_unit-${study_unit.id}`"
                    :showColorCognitiveLevel="false"
                    :Sentence="study_unit"
                    :boundedTextContainer="short_view"
                    :single_line="short_view"
                  >
                    <template v-slot:order>
                      <span>
                        <span> {{ study_unit.order }}.</span>
                      </span>
                    </template>
                  </SentenceContainer>
                </div>
              </b-td>
              <b-td class="text-center text-danger"> - </b-td>
              <b-td class="text-center text-danger"> - </b-td>
              <b-td class="text-center text-danger"> - </b-td>
              <b-td class="text-center text-danger">
                <b-badge variant="danger">0</b-badge>
              </b-td>
            </b-tr>
          </template> -->
        </template>
      </b-tbody>
    </b-table-simple>
    <b-table-simple v-if="studyUnitsFilter.length > 0">
      <b-thead>
        <b-tr>
          <b-th class="text-align-center evaluation-header py-2" colspan="9">
            RESUMEN DE
            {{
              $getVisibleNames(
                "teaching.evaluationcriteriamicro",
                true,
                "Criterio de Evaluación Micro"
              ).toUpperCase()
            }}
            EVALUADOS EN LA
            {{
              $getVisibleNames(
                "mesh.egressprofilematter",
                false,
                "Asignatura"
              ).toUpperCase()
            }}
          </b-th>
        </b-tr>
        <b-tr>
          <b-th class="text-align-center text-left evaluation-header py-2">
            TOTAL DE
            {{
              $getVisibleNames(
                "teaching.evaluationcriteriamicro",
                true,
                "Criterio de Evaluación Micro"
              ).toUpperCase()
            }}</b-th
          >
          <b-th class="text-align-center text-left evaluation-header py-2">
            {{
              $getVisibleNames(
                "teaching.evaluationcriteriamicro",
                true,
                "Criterio de Evaluación Micro"
              ).toUpperCase()
            }}
            NO MENCIONADOS
          </b-th>
          <b-th class="text-align-center evaluation-header py-2"> % </b-th>
          <b-th class="text-align-center text-left evaluation-header py-2">
            TOTAL DE PREGUNTAS
          </b-th>
          <b-th class="text-align-center text-left evaluation-header py-2">
            TOTAL DE PREGUNTAS NO ASOCIADAS A
            {{
              $getVisibleNames(
                "teaching.evaluationcriteriamicro",
                true,
                "Criterio de Evaluación Micro"
              ).toUpperCase()
            }}
          </b-th>
          <b-th class="text-align-center evaluation-header py-2"> % </b-th>
          <b-th class="text-align-center text-left evaluation-header py-2">
            TOTAL DE
            {{
              $getVisibleNames(
                "manual.dimension",
                true,
                "Dimensiones"
              ).toUpperCase()
            }}
            EN INSTRUMENTOS DE OBSERVACIÓN
          </b-th>
          <b-th class="text-align-center text-left evaluation-header py-2">
            {{
              $getVisibleNames(
                "manual.dimension",
                true,
                "Dimensiones"
              ).toUpperCase()
            }}
            NO ASOCIADAS A
            {{
              $getVisibleNames(
                "teaching.evaluationcriteriamicro",
                true,
                "Criterio de Evaluación Micro"
              ).toUpperCase()
            }}
          </b-th>
          <b-th class="text-align-center evaluation-header py-2"> % </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td class="text-center p-2">
            {{ ECMicroFilterByStudyUnit.length }}
          </b-td>
          <b-td class="text-center p-2">
            {{ excludeECMicro.length }}
          </b-td>
          <b-td class="text-center p-2">
            <span v-if="ECMicroFilterByStudyUnit.length != 0">
              {{
                ((excludeECMicro.length / ECMicroFilterByStudyUnit.length) *
                  100)
                  | Round
              }}%
            </span>
            <span v-else> 0% </span>
          </b-td>
          <b-td class="text-center p-2">{{ questionList.length }}</b-td>
          <b-td class="text-center p-2">
            <span
              v-if="getQuestionIndicators(null).length > 0"
              :id="`questions-null-popover`"
              class="helper"
            >
              <!-- :class="{ helper: getQuestionIndicators(null).length > 0 }" -->
              <b-badge class="border" variant="light">
                {{ getQuestionIndicators(null).length }}
              </b-badge>
            </span>
            <span v-else> 0 </span>
            <b-popover
              v-if="getQuestionIndicators(null).length > 0"
              :target="`questions-null-popover`"
              placement="left"
              triggers="hover"
              variant="dark"
            >
              <div>
                <div v-if="getQuestionIndicators(null).length > 0">
                  <strong
                    v-if="
                      getInstrumentsByQuestions(getQuestionIndicators(null))
                        .length > 1
                    "
                    >{{
                      $getVisibleNames(
                        "evaluations2.test",
                        true,
                        "Instrumentos"
                      )
                    }}:</strong
                  >
                  <strong
                    v-if="
                      getInstrumentsByQuestions(getQuestionIndicators(null))
                        .length == 1
                    "
                  >
                    {{
                      $getVisibleNames(
                        "evaluations2.test",
                        false,
                        "Instrumento"
                      )
                    }}:
                  </strong>
                </div>
                <div
                  v-for="(filter_test, index) in getInstrumentsByQuestions(
                    getQuestionIndicators(null)
                  )"
                  :key="index + 'filter_test'"
                >
                  {{ filter_test.title }}:
                  <li>
                    <strong>
                      {{
                        getQuestionIndicators(null)
                          .filter((x) => x.test == filter_test.id)
                          .map((x) =>
                            x.segment_order
                              ? x.segment_order + "." + x.order
                              : x.order
                          )
                          .join(", ")
                      }}
                    </strong>
                  </li>
                </div>
              </div>
            </b-popover>
          </b-td>
          <b-td class="text-center p-2">
            <template v-if="questionList.length > 0">
              {{
                ((getQuestionIndicators(null).length / questionList.length) *
                  100)
                  | Round
              }}%
            </template>
            <template v-else>0</template>
          </b-td>
          <b-td class="text-center p-2">{{ rubricCriteriaList.length }}</b-td>
          <b-td class="text-center p-2">
            <span
              v-if="getRubricCriteriaIndicators(null).length > 0"
              :id="`rubric-null-popover`"
              class="helper"
            >
              <!-- :class="{ helper: getRubricCriteriaIndicators(null).length > 0 }" -->
              <b-badge class="border" variant="light">
                {{ getRubricCriteriaIndicators(null).length }}
              </b-badge>
            </span>
            <span v-else>0</span>
            <b-popover
              v-if="getRubricCriteriaIndicators(null).length > 0"
              :target="`rubric-null-popover`"
              placement="left"
              triggers="hover"
              variant="dark"
            >
              <div>
                <div v-if="getRubricCriteriaIndicators(null).length > 0">
                  <strong
                    v-if="
                      getInstrumentsByRubricCriteria(
                        getRubricCriteriaIndicators(null)
                      ).length > 1
                    "
                    >{{
                      $getVisibleNames(
                        "evaluations2.test",
                        true,
                        "Instrumentos"
                      )
                    }}:</strong
                  >
                  <strong
                    v-if="
                      getInstrumentsByRubricCriteria(
                        getRubricCriteriaIndicators(null)
                      ).length == 1
                    "
                  >
                    {{
                      $getVisibleNames(
                        "evaluations2.test",
                        false,
                        "Instrumento"
                      )
                    }}:
                  </strong>
                </div>
                <div
                  v-for="(filter_test, index) in getInstrumentsByRubricCriteria(
                    getRubricCriteriaIndicators(null)
                  )"
                  :key="index + 'filter_test-rubric'"
                >
                  {{ filter_test.title }}:
                  <li>
                    <strong>
                      {{
                        getRubricCriteriaIndicators(null)
                          .filter((x) =>
                            rubricList.some(
                              (r) =>
                                r.id == x.rubric && r.test == filter_test.id
                            )
                          )
                          .map((x) => x.order)
                          .join(", ")
                      }}
                    </strong>
                  </li>
                </div>
              </div>
            </b-popover>
          </b-td>
          <b-td class="text-center p-2">
            <template v-if="rubricCriteriaList.length > 0">
              {{
                ((getRubricCriteriaIndicators(null).length /
                  rubricCriteriaList.length) *
                  100)
                  | Round
              }}%
            </template>
            <template v-else> 0 </template>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "ILIndicatorsUsage",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
  },
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
    ecm_no_mention: {
      type: Boolean,
      default: false,
    },
    short_view: {
      type: Boolean,
      default: false,
    },
    evaluations_ids: {
      type: Array,
      required: true,
    },
    includes_ecm: {
      type: Array,
      default() {
        return [];
      },
    },
    fetch_evaluations: {
      type: Boolean,
      default: false,
    },
    fetch_evaluation_criteria_micro: {
      type: Boolean,
      default: false,
    },
    fetch_competence_unit: {
      type: Boolean,
      default: false,
    },
    show_taxonomy_color: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      studyUnits: names.STUDY_UNITS,
      evaluationCriterias: names.EVALUATION_CRITERIAS,
      matterEvaluations: names.MATTER_EVALUATIONS,
      // evaluation_types: names.NEW_EVALUATION_TYPES,
      evaluations: names.NEW_EVALUATIONS,
      tests: names.NEW_TESTS,
      test_segments: names.NEW_TEST_SEGMENTS,
      redaction_questions: names.NEW_REDACTION_QUESTIONS,
      multiple_choice_questions: names.NEW_MULTIPLE_CHOICE_QUESTIONS,
      // filling_questions: names.NEW_FILLING_QUESTIONS,
      matching_questions: names.NEW_MATCHING_QUESTIONS,
      rubrics: names.NEW_RUBRIC_INSTRUMENTS,
      rubricCriterias: names.NEW_RUBRIC_CRITERIAS,
      competence_units: names.COMPETENCE_UNITS,
    }),
    evaluationList() {
      return this.evaluations.filter((x) =>
        this.evaluations_ids.includes(x.id)
      );
    },
    testList() {
      return this.tests.filter((x) =>
        this.evaluations_ids.includes(x.evaluation)
      );
    },
    testsIds() {
      return this.testList.map((x) => x.id);
    },
    rubricList() {
      return this.rubrics.filter((x) => this.testsIds.includes(x.test));
    },
    rubricCriteriaList() {
      const rubrics_ids = this.rubricList.map((x) => x.id);
      return this.rubricCriterias.filter((x) => rubrics_ids.includes(x.rubric));
    },
    redactionQuestions() {
      return this.redaction_questions.filter((x) =>
        this.testsIds.includes(x.test)
      );
    },
    multipleChoiceQuestions() {
      return this.multiple_choice_questions.filter((x) =>
        this.testsIds.includes(x.test)
      );
    },
    // fillingQuestions() {
    //   return this.filling_questions.filter((x) => this.testsIds.includes(x.test));
    // },
    matchingQuestions() {
      return this.matching_questions.filter((x) =>
        this.testsIds.includes(x.test)
      );
    },
    questionList() {
      let list = [];
      list = list.concat(
        this.redactionQuestions.map((question) => ({
          ...question,
          question_type: 1,
          segment_order:
            (this.test_segments.find((s) => s.id == question.segment) || {})
              .order || null,
        }))
      );
      list = list.concat(
        this.multipleChoiceQuestions.map((question) => ({
          ...question,
          question_type: 2,
          segment_order:
            (this.test_segments.find((s) => s.id == question.segment) || {})
              .order || null,
        }))
      );
      // list = list.concat(
      //   this.fillingQuestions.map((question) => ({
      //     ...question,
      //     question_type: 3,
      //     segment_order:
      //       (this.test_segments.find((s) => s.id == question.segment) || {}).order ||
      //       null,
      //   }))
      // );
      list = list.concat(
        this.matchingQuestions.map((question) => ({
          ...question,
          question_type: 4,
          segment_order:
            (this.test_segments.find((s) => s.id == question.segment) || {})
              .order || null,
        }))
      );
      return list.sort(function (a, b) {
        if (a.segment_order < b.segment_order) return -1;
        if (a.segment_order > b.segment_order) return 1;
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    },
    studyUnitsFilterByMatter() {
      return this.studyUnits
        .filter((x) => x.matter == this.matter_id)
        .map((x) => {
          const competence_unit = this.competence_units.find(
            (c) => c.id == x.temp_competence_unit
          );
          return {
            ...x,
            competence_unit_order: competence_unit
              ? competence_unit.order
              : 999,
          };
        })
        .sort(function (a, b) {
          if (a.competence_unit_order > b.competence_unit_order) return 1;
          if (a.competence_unit_order < b.competence_unit_order) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    ECMicroFilterByStudyUnit() {
      const study_unit_ids = this.studyUnitsFilterByMatter.map((x) => x.id);
      if (this.includes_ecm.length > 0)
        return this.evaluationCriterias
          .filter(
            (x) =>
              study_unit_ids.includes(x.study_unit) &&
              this.includes_ecm.includes(x.id)
          )
          .sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
          });
      else
        return this.evaluationCriterias
          .filter((x) => study_unit_ids.includes(x.study_unit))
          .sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
          });
    },
    excludeECMicro() {
      let evaluation_criteria_micro_ids = [];
      // Caso DUOC
      if (this.institution && this.institution.internal_use_id == "duoc_uc") {
        // questionList
        evaluation_criteria_micro_ids = evaluation_criteria_micro_ids.concat(
          this.questionList.map((x) => x.evaluation_criteria_micro)
        );
        // rubricCriteriaList
        evaluation_criteria_micro_ids = evaluation_criteria_micro_ids.concat(
          this.rubricCriteriaList.map((x) => x.evaluation_criteria_micro)
        );
      } else {
        // questionList
        evaluation_criteria_micro_ids = evaluation_criteria_micro_ids.concat(
          this.questionList.map((x) => x.evaluation_criterias_micro).flat()
        );
        // rubricCriteriaList
        evaluation_criteria_micro_ids = evaluation_criteria_micro_ids.concat(
          this.rubricCriteriaList
            .map((x) => x.evaluation_criterias_micro)
            .flat()
        );
      }
      evaluation_criteria_micro_ids = [
        ...new Set(evaluation_criteria_micro_ids),
      ];
      return this.ECMicroFilterByStudyUnit.filter(
        (x) => !evaluation_criteria_micro_ids.includes(x.id)
      );
    },
    studyUnitsFilter() {
      if (!this.ecm_no_mention) return this.studyUnitsFilterByMatter;
      else {
        const study_units_ids = [
          ...new Set(this.excludeECMicro.map((x) => x.study_unit)),
        ];
        return this.studyUnitsFilterByMatter
          .filter((x) => study_units_ids.includes(x.id))
          .map((x) => {
            const competence_unit = this.competence_units.find(
              (c) => c.id == x.temp_competence_unit
            );
            return {
              ...x,
              competence_unit_order: competence_unit
                ? competence_unit.order
                : 999,
            };
          })
          .sort(function (a, b) {
            if (a.competence_unit_order > b.competence_unit_order) return 1;
            if (a.competence_unit_order < b.competence_unit_order) return -1;
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
          });
      }
    },
    ECMicroFilter() {
      if (!this.ecm_no_mention) return this.ECMicroFilterByStudyUnit;
      else return this.excludeECMicro;
    },
  },
  methods: {
    getCompetenceUnitOrder(temp_competence_unit_id) {
      const competence_unit = this.competence_units.find(
        (x) => x.id == temp_competence_unit_id
      );
      return competence_unit ? competence_unit.order + "." : null;
    },
    getQuestionIndicators(indicator) {
      if (this.institution && this.institution.internal_use_id == "duoc_uc") {
        return this.questionList.filter(
          (x) => x.evaluation_criteria_micro == indicator
        );
      } else {
        if (indicator != null)
          return this.questionList.filter((x) =>
            x.evaluation_criterias_micro.includes(indicator)
          );
        else
          return this.questionList.filter(
            (x) => x.evaluation_criterias_micro.length == 0
          );
      }
    },
    getRubricCriteriaIndicators(indicator) {
      if (this.institution && this.institution.internal_use_id == "duoc_uc") {
        return this.rubricCriteriaList.filter(
          (x) => x.evaluation_criteria_micro == indicator
        );
      } else {
        if (indicator != null)
          return this.rubricCriteriaList.filter((x) =>
            x.evaluation_criterias_micro.includes(indicator)
          );
        else
          return this.rubricCriteriaList.filter(
            (x) => x.evaluation_criterias_micro.length == 0
          );
      }
    },
    ECMicroByStudyUnits(study_unit_id) {
      return this.ECMicroFilter.filter((x) => x.study_unit == study_unit_id);
    },
    getListByECMicro(e_c_micro_id) {
      // CASO DUOC
      if (this.institution && this.institution.internal_use_id == "duoc_uc") {
        return this.testList
          .filter(
            (test) =>
              this.questionList.some(
                (question) =>
                  question.test == test.id &&
                  question.evaluation_criteria_micro == e_c_micro_id
              ) ||
              this.rubricList.some(
                (rubric) =>
                  rubric.test == test.id &&
                  this.rubricCriteriaList.some(
                    (il) =>
                      il.rubric == rubric.id &&
                      il.evaluation_criteria_micro == e_c_micro_id
                  )
              )
          )
          .sort((a, b) => a.title.localeCompare(b.title));
      } else {
        return this.testList
          .filter(
            (test) =>
              this.questionList.some(
                (question) =>
                  question.test == test.id &&
                  question.evaluation_criterias_micro.includes(e_c_micro_id)
              ) ||
              this.rubricList.some(
                (rubric) =>
                  rubric.test == test.id &&
                  this.rubricCriteriaList.some(
                    (il) =>
                      il.rubric == rubric.id &&
                      il.evaluation_criterias_micro.includes(e_c_micro_id)
                  )
              )
          )
          .sort((a, b) => a.title.localeCompare(b.title));
      }
    },
    getInstrumentsByQuestions(questions) {
      const tests_ids = [...new Set(questions.map((x) => x.test))];
      return this.testList.filter((x) => tests_ids.includes(x.id));
    },
    getInstrumentsByRubricCriteria(rubric_criterias) {
      const rubric_ids = [...new Set(rubric_criterias.map((x) => x.rubric))];
      return this.testList.filter((t) =>
        this.rubricList.some((r) => rubric_ids.includes(r.id) && r.test == t.id)
      );
    },
    // nameTypes(id_type) {
    //   let name = this.evaluation_types.find((x) => x.id == id_type);
    //   return name ? name.value : "";
    // },
    filterMentionsTest(e_c_micro_id) {
      return this.getListByECMicro(e_c_micro_id)
        .map((y) => y.title)
        .sort((a, b) => a.localeCompare(b));
    },
  },
  created() {
    // fetch_evaluations
    if (this.fetch_evaluations && this.evaluations_ids.length > 0) {
      this.$store.dispatch(names.FETCH_NEW_EVALUATIONS, {
        evaluations_ids: this.evaluations_ids,
      });
      this.$store
        .dispatch(names.FETCH_NEW_TESTS, {
          evaluations_ids: this.evaluations_ids,
        })
        .then((tests) => {
          const tests_ids = tests.map((x) => x.id);
          this.$store.dispatch(names.FETCH_NEW_TEST_SEGMENTS, {
            tests_ids: tests_ids,
          });
          this.$store.dispatch(names.FETCH_NEW_REDACTION_QUESTIONS, {
            tests_ids: tests_ids,
          });
          this.$store.dispatch(names.FETCH_NEW_MULTIPLE_CHOICE_QUESTIONS, {
            tests_ids: tests_ids,
          });
          this.$store.dispatch(names.FETCH_NEW_FILLING_QUESTIONS, {
            tests_ids: tests_ids,
          });
          this.$store.dispatch(names.FETCH_NEW_MATCHING_QUESTIONS, {
            tests_ids: tests_ids,
          });
          this.$store.dispatch(names.FETCH_NEW_RUBRIC_INSTRUMENTS, {
            tests_ids: tests_ids,
          });
        });
    }
    // fetch_evaluation_criteria_micro
    if (this.fetch_evaluation_criteria_micro && this.matter_id) {
      this.$store.dispatch(names.FETCH_STUDY_UNITS, this.matter_id);
      this.$store.dispatch(names.FETCH_EVALUATION_CRITERIAS, {
        matter_id: this.matter_id,
      });
    }
    // fetch_competence_unit
    if (
      this.fetch_competence_unit &&
      this.matter_id &&
      this.institution &&
      (this.institution.internal_use_id == "ciisa_uss" ||
        this.institution.show_competence_unit)
    ) {
      this.$store.dispatch(names.FETCH_COMPETENCE_UNITS, {
        matter__id: this.matter_id,
      });
    }
    // this.$store.dispatch(names.FETCH_NEW_EVALUATION_TYPES);
  },
};
</script>

<style scoped>
table th {
  vertical-align: middle;
  background-color: var(--primary-color);
  color: white;
  font-size: var(--primary-font-size);
  padding: 5px 5px;
}
.evaluation-header {
  color: black !important;
  background: var(--primary-color) !important;
  border: 1px solid #000 !important;
}
table td {
  border: 1px solid black;
  text-align: left;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.badge {
  margin: 0.2rem;
  font-size: 100% !important;
  margin-left: 0.5rem;
  vertical-align: middle;
}
.helper {
  cursor: help;
}
@media print {
  .badge {
    border: none;
  }
}
</style>

